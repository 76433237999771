/* You can add global styles to this file, and also import other style files */
@import "assets/less/fonts";
@import "assets/less/vars";
@import "assets/less/unified";


html {
  position: relative;
  min-height: 100%;
  overflow-y: scroll;
}
body {
  margin: 0;
  &.modal-open {
    padding: 0!important;
  }
}
main {
  padding: 0 0 35px 0;
  margin-top: 0;
  flex: 1;
}

app-alert {
  z-index: 999999;
  overflow: hidden;
  position: fixed;
  top: 55px;
  right: 15px;
}


.ngx-pagination {
  margin: 0;
  font-size: 14px;
  li {
    cursor: pointer;
    background-color: #ffffff;
    border: 1px solid #CDCED9;
    transition: all 250ms;
    border-radius: 3px!important;
    width: 35px;
    text-align: center;
    &:focus {
      outline: none;
    }
    &:hover {
      background-color: @theme_color;
      border: 1px solid @theme_color;
      a {
        color: #ffffff;
      }
    }
    &.current {
      background-color: @theme_color;
      border: 1px solid @theme_color;
      a {
        color: #ffffff;
      }
    }
    a {
      text-decoration: none;
      transition: all 250ms;
      color: #CDCED9;
      padding: 3px 0!important;
      &:hover {
        color: #ffffff;
        background-color: @theme_color;
      }
      &:focus {
        outline: none;
      }
    }
  }
}
.progress {
  position: relative;
  border-radius: 8px;
  border: 1px solid #CDCED9;
  background-color: #ffffff;
  bar {
    i {
      position: absolute;
      left: 50%;
      top: 50%;
      min-width: 30px;
      margin-left: -15px;
      color: black;
      font-size: 12px;
    }
  }
  bar {
    background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
    background-size: 1rem 1rem;
    animation: progress-bar-stripes 1s linear infinite;
  }
  &.danger {
    bar {
      background-color: #ff0000!important;
    }
  }
  &.warning {
    bar {
      background-color: #ffc107!important;
    }
  }
  &.normal {
    bar {
      background-color: #51a5ff!important;
      i {
        // color: #ffffff;
      }
    }
  }
}
@keyframes progress-bar-stripes {
  0% { background-position: 0 16px; }
  100% { background-position: 0 0; }
}


.modal {
  .modal-dialog {
    .modal-content {
      &>div {
        border-color: #E7E7ED;
      }
      .modal-header {
        position: relative;
        .modal-title {
          font-weight: bold;
          font-size: 20px;
          color: #272833;
        }
        .close {
          &:focus {
            outline: none;
          }
        }
      }
      .modal-body {}
      .modal-footer {
        .modal-cancel-btn {
          background-color: @theme_color;
          color: #ffffff;
          border-radius: 4px;
          font-weight: 500;
          font-size: 16px;
          border: none;
          margin: 0;
          padding: 5px 10px;
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}
