@import "./vars";
.main-action-btn {
  background-color: @theme_color;
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  border: 2px solid @theme_color;
  margin-left: 10px;
}
.main-cancel-btn {
  background-color: #ffffff;
  padding: 10px 20px;
  border: 2px solid @theme_color;
  border-radius: 5px;
  color: @theme_color;
}
